@font-face {
  font-family: 'galaxie-polaris-light';
  src: url('/assets/fonts/polaris/GalaxiePolaris-Light.woff') format('woff'),
    url('/assets/fonts/polaris/GalaxiePolaris-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galaxie-polaris-book';
  src: url('/assets/fonts/polaris/GalaxiePolaris-Book.woff') format('woff'),
    url('/assets/fonts/polaris/GalaxiePolaris-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galaxie-polaris-bold';
  src: url('/assets/fonts/polaris/GalaxiePolaris-Bold.woff') format('woff'),
    url('/assets/fonts/polaris/GalaxiePolaris-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galaxie-polaris-medium';
  src: url('/assets/fonts/polaris/GalaxiePolaris-Medium.woff') format('woff'),
    url('/assets/fonts/polaris/GalaxiePolaris-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galaxie-polaris-heavy';
  src: url('/assets/fonts/polaris/GalaxiePolaris-Heavy.woff') format('woff'),
    url('/assets/fonts/polaris/GalaxiePolaris-Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  background-color: #fff;
  color: #000;
  font-family: 'galaxie-polaris-medium', 'Helvetica', Arial, sans-serif;
  height: 100%;
}

body {
  font-size: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

.disable-scroll {
  overflow: hidden !important;
}

body > * {
  height: 100%;
}

body main {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus,
textarea:focus,
input:focus,
button:focus,
input[type]:focus {
  outline: 1px dotted #EB3337;
}

p a {
  text-decoration: underline;
}

canvas {
  line-height: normal;
}

strong,
.bold,
b {
  font-weight: bold;
}

em,
.i {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.no-under {
  text-decoration: none;
}

svg {
  width: 100%;
  height: 100%;
  display: block;
}

img {
  width: 100%;
  display: block;
  border: none;
}

button {
  background: none;
  border-radius: 0;
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
}

input {
  border: 0;
  border-radius: 0;
  background: none;
  appearance: none;
}

[ani],
[data-ani] {
    opacity: 0;
}